export enum LICENSE_TYPE {
  Customer = "Customer",
  POC = "POC",
  Training = "Training",
  Internal = "Internal",
  Trial = "Trial",
  SI = "SI",
  End_User = "End_User",
  ONPREM = "ONPREM",
  SAAS = "SAAS",
  POC_ONPREM = "POC_ONPREM",
}

export enum PRODUCT {
  Relocate = "Relocate",
  Renovate = "Renovate",
  DCIO_PLUS_SUBSCRIPTION = "DCIO_PLUS_SUBSCRIPTION",
  Code_Navigator = "Code_Navigator",
}

export enum LINES_OF_CODE_CATEGORY {
  LOW = 0,
  HIGH = 10000,
}

export enum SUPPORT_LEVEL {
  PREMIUM = "PREMIUM",
  PREMIUM_PLUS = "PREMIUM_PLUS",
}

export enum LICENSE_STATUS {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  REVOKED = "REVOKED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
}
